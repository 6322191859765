//TODO remove all scss appearances
.common{
  &__error-container{
    //border:1px solid #d32f2f;
    background-color: rgba(255,0,0,0.30)!important;
  }
  &__card{
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    &_blue {
      background-color: rgba(0, 125, 255, 0.15) !important;
    }
    &_error{
      background-color: rgba(255,0,0,0.30)!important;

    }
  }
  &__row{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__accordion-row-summary{
    .MuiAccordionSummary-content{
      width:100%;
    }
  }
  &__ellipsis-text{
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

  }
}
